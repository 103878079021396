<!-- 分类管理 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <div class="hd">
      <div class="name">
        <span>问答分类</span>
      </div>
      <div class="gray"></div>
      <div class="common-screen-container">
        <div class="common-input-container">
          <span>分类名称:</span>
          <el-input
            class="common-screen-input"
            v-model="typeName"
            placeholder="请输入"
          ></el-input>
          <el-button class="common-screen-btn" type="primary" @click="commonFun"
            >查 询</el-button
          >
          <el-button class="common-screen-btn" @click="() => resetBtn()" plain
            >重 置</el-button
          >
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="padding">
      <el-table
        :data="tableData"
        style="width: 100%"
        class="common-table common-table-bgGrey"
      >
        <el-table-column prop="typeName" label="分类名称"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { listNew } from "../../service/auto";

export default {
  name: "autoManage",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      typeName: "",
      tableData: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getTableData() {
      this.loading = true;
      const { data } = await listNew({ typeName: this.typeName });
      this.loading = false;
      this.tableData = data;
    },
    // 查询数据
    commonFun() {
      this.getTableData();
    },
    // 重置
    resetBtn() {
      this.typeName = "";
      this.getTableData();
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .padding {
    padding: 16px 32px;
  }
  .hd {
    height: 153px;
    .name {
      display: flex;
      justify-content: space-between;
      color: rgba(16, 16, 16, 100);
      font-size: 24px;
      padding: 18px 32px;
      span {
        font-size: 24px;
      }
      .addBtn {
        border-color: #1890ff;
        color: #1890ff;
      }
    }
    .gray {
      height: 1px;
      background-color: rgba(230, 230, 230, 100);
      margin-bottom: 24px;
    }
    .common-screen-container {
      padding-left: 32px;
    }
  }
  .line {
    height: 16px;
    background-color: #ededed;
  }
}
</style>
